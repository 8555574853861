<template>
  <div class="mainArea">
    <div class="contentCenter">
      <h1 class="underline">NEMO BLEUER</h1>
      <h1>LONG LIVE THE NEW FLESH</h1>
      <h2>Installation</h2>
      <br>
      <h4>Opening: Do. 05.12.24, 18:00</h4>
      <h2>Finissage: Fr. 20.12.24, 18:00</h2>
      <br>
      <h2>06.12.24 - 20.12.24</h2>
      <h2>Visits by appointment: mail@fomoartspace.ch</h2>
      <br>
      <img src="../assets/img/upcoming_12.jpg" alt="" class="contentImgBig">
      <br>
      <h4>A lot has changed since the last missing piece was found. Everything is still alive, still moving, resonating with the loud electrical noise that disrupts the air. But something is different...</h4>
      <br>
      <h2>Created by biotechnological innovation, semi-living beings form a new category of life. These biofacts not only promise a bright future, they also provoke abjection and uncanny feelings. Nemo Bleuer’s work is centered around these fears and the eeriness that arises when dualisms like “natural and artificial” dissolve. Rather than adhering to a strictly utopian or dystopian perspective, Bleuer activates a gateway into a twilight realm in which humans slowly vanish to make room for artificial beings to thrive.</h2>
      <a href="https://www.instagram.com/_orrrg/" target="_blank"><h5 class="underline">Nemo Bleuer on Instagram</h5></a>
    </div>
  </div>
</template>
<script>
  export default { 
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>